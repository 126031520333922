<template>
  <div class="page">
    <el-row class="tool-bar">
      <el-col :span="12">
        <search-tool v-model="search" @input="$utils('search', getDoplate)"/>
      </el-col>
      <el-col :span="12" align="right">
        <el-button type="success" icon="el-icon-plus" @click="$utils('goTo', '/doplate/create')" v-if="$utils('roleUser', 'Cenovnik', 'create')">{{ $t('global.novo') }}</el-button>
      </el-col>
    </el-row>
    <el-row>
      <!--Table-->
      <el-table v-if="doplata.length"
                :data="doplata"
                ref="table"
                :default-sort="sortBy"
                @sort-change="sort => $utils('changeSort', sort, getDoplate)"
                v-loading="gridLoad"
                border
                stripe>
        <el-table-column
                align="left"
                prop="naziv_linije"
                :label="$t('doplata.naziv_linije')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="right"
                prop="fiksna_cena"
                :label="$t('doplata.fiksna_cena')"
                sortable="custom">
            <template slot-scope="scope">
                {{$utils('formatBroja', scope.row.fiksna_cena)}}
            </template>
        </el-table-column>
        <el-table-column
                align="right"
                prop="procenat"
                :label="$t('doplata.procenat')"
                sortable="custom">
            <template slot-scope="scope">
                {{$utils('formatBroja', scope.row.procenat)}} %
            </template>
        </el-table-column>
        <el-table-column
                align="right"
                prop="min_cena"
                :label="$t('doplata.min_cena')"
                sortable="custom">
            <template slot-scope="scope">
                {{$utils('formatBroja', scope.row.min_cena)}}
            </template>
        </el-table-column>
        <el-table-column
                align="left"
                prop="model_doplate"
                :label="$t('doplata.model_doplate')"
                sortable="custom">
        </el-table-column>
        <el-table-column
                align="center"
                :label="$t('doplata.datumDoplata')">
           <template slot-scope="scope">
               {{$utils('formatDate', scope.row.datum_od)}} – {{$utils('formatDate', scope.row.datum_do)}}
           </template>
        </el-table-column>
        <el-table-column
                align="center"
                :label="$t('doplata.kartaDatum')">
           <template slot-scope="scope">
               {{$utils('formatDate', scope.row.karta_datum_od)}} – {{$utils('formatDate', scope.row.karta_datum_do)}}
           </template>
        </el-table-column>
        <el-table-column
                width="120">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="$t('global.izmena')" placement="top" v-if="$utils('roleUser', 'Cenovnik', 'edit')">
              <el-button type="success" icon="fas fa-pen" @click="$utils('goTo', '/doplate/' + scope.row.id + '')" size="small"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" :content="$t('global.brisanje')" placement="top" v-if="$utils('roleUser', 'Cenovnik', 'delete')">
              <el-button type="danger" icon="fas fa-trash" @click="$delete('ceneDoplate', scope.row.id)" size="small"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-alert v-else :title="$t('global.nemaPodataka')" type="warning" :closable="false"/>
    </el-row>
    <br>
    <el-row>
      <pagination v-model="pagination" @input="getDoplate" ref="pagination"/>
    </el-row>
  </div>
</template>

<script>
export default {
	name: 'doplata-list',
	data() {
		return {
			doplata: [],
			gridLoad: false,
			pagination: {},
			search: '',
			sortBy: {
				prop: 'naziv_linije',
				order: 'ascending'
			}
		};
	},
	mounted() {
		this.$store.dispatch('setHeader', {
			title: this.$t('doplata.doplata')
		});
		this.$utils('autoLoad');
		this.$utils('stopLoadingAfter', [this.getDoplate()]);
	},
	methods: {
		getDoplate() {
			this.gridLoad = true;
			return this.$get('ceneDoplate', this.$utils('getParams')).then(data => {
				this.doplata = data.content;
				this.$utils('setPag', data);
				this.$utils('autoSave');
				this.gridLoad = false;
			});
		}
	}
};
</script>
